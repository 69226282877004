<template>
    <div class="advice-container">
        <advice-alerts :alerts="alerts"/>
        <div class="graph-container" :class="{inline: isInline}">
            <div class="value-box" v-if="measurement">
                <span class="value">{{ measurement.value }}</span>
                <span class="unit">{{ measurement.cfg.unit }}</span>
                <div class="date">{{ measDate }}</div>
            </div>
            <w-line-graph class="mt-4"
                          v-if="measurement"
                          :value="measurement.value"
                          :ranges="measurement.cfg.floatRanges || measurement.cfg.intRanges"
                          :labels="isInline"
                          :point="!isInline"
                          :unit="measurement.type === 'FREE_CL' ? measurement.cfg.unit : ''"
            />
        </div>
        <advice-status v-if="object.message" :abbr="object.message.badge">
            <translate :text="macros(object.message.text, object.message.error)"></translate>
        </advice-status>

        <component :is="getAdviceComponent(step.component.type)" :object="object" :locale="locale" @submit="submitted"/>

        <advice-read-more :reade-more-blocks="readMoreBlocks" :toggled="$route.query.read_more"/>

        <advice-chart v-if="categoryObj && categoryObj.history_key && measurement && !isInline && showGraph" :cfg="measurement.cfg" :get-history="getHistory" :colors="$store.getters.statusColors"/>

        <ProductPanel v-if="showProductPanel && product" :product="product"/>

        <advice-buttons v-else-if="(isBack || (object.edit ? object.edit.active : false)) && !isInline"
                        :continue-route="{ name: 'wizard', query: { questions: object.edit ? object.edit.questions : [], title: categoryObj.edit_title } }"
                        :show-back="isBack" @continue="edit" @back="back"
                        :show-continue="object.edit ? object.edit.active : false">
            <template v-slot:continueText>
                <translate :text="object.edit ? object.edit.text : ''"/>
            </template>
            <template v-slot:backText>
                {{ $t("button.back") }}
            </template>
        </advice-buttons>
    </div>
</template>

<script>

import { adviceGuard } from '../helpers'
import { lambda } from '../amplify'
import { getHistory } from '../api'
import Loader from '../components/Loader'
import groupedSteps from '../assets/json/steps'
import { format } from 'date-fns'
import ProductPanel from '../components/ProductPanel'
import { mapGetters } from 'vuex'
import Translate from '../components/Translate'
import i18n from '../i18n'

export default {
    name: 'Advice',
    components: { ProductPanel, Loader, Translate },
    data () {
        return {
            response: null,
            object: {},
            showProductPanel: true // process.env.VUE_APP_PRODUCT_BAR === '1'
        }
    },
    computed: {
        ...mapGetters(['buyProductsLink', 'products']),
        categoryObj () {
            const _category = this.$route.params.category ? this.$route.params.category.toUpperCase() : false
            return groupedSteps.find(({ category }) => category === _category)
        },
        measurement () {
            return this.$store.getters.measurements.find(({ type }) => type === this.categoryObj.measurement.key)
        },
        alerts () {
            const category = this.$route.params.category ? this.$route.params.category.toUpperCase() : false
            const measured = !!this.measurement
            const alerts = this.$store.getters.alerts.filter(({ source }) => source === category)
            const { ok_alert, not_measured_alert } = this.categoryObj.measurement
            return alerts.length ? alerts.map(({ source, status, text }) => ({ key: source, status, text })) : [
                {
                    key: 1,
                    status: measured ? 'GREEN' : 'RED',
                    text: measured ? ok_alert : not_measured_alert
                }
            ]
        },
        step () {
            const waterBody = this.$store.getters.waterBody
            const step = this.categoryObj.advices.find(({ show_case, alert_case }) => {
                console.log(`*** Checking show_case = ${JSON.stringify(show_case)}, alert_case = ${JSON.stringify(alert_case)}`);
                //   "show_case": [
                //     {
                //        "key": "userCl",
                //        "value": "TRICHLOR"
                //     },
                //     {
                //        "key": "userTrich",
                //        "value": "FLOATING|INLINE"
                //     }
                const typeMatches = (!show_case ||
                    show_case.every(({ key, value }) => {
                        console.log(`waterBody[key] = ${waterBody[key]}`);
                        return value.split('|').includes(waterBody[key]);
                    }));
                console.log(`*** typeMatches = ${typeMatches}`);

                // "alert_case": {
                //     "FREE_CL": "LOW|VERY_LOW"
                // },
                const conditionMatches = (!alert_case ||
                    Object.keys(alert_case).every(chemistry => {
                                                const alert = this.$store.getters.alerts.find(({ source }) => source === chemistry);
                                                console.log(`alert = ${JSON.stringify(alert)}`);
                                                console.log(`alert?.condition = ${alert?.condition}`);
                                                return alert && alert_case[chemistry].split('|').includes(alert.condition)
                                            }));
                console.log(`*** conditionMatches = ${conditionMatches}`);

                return typeMatches && conditionMatches;
            })

            console.log(`*** step = ${JSON.stringify(step)}`);

            // TODO: returning advices[0] if not found is stupid & incorrect!!!
            return step || this.categoryObj.advices[0]
        },
        readMoreBlocks () {
            if (!this.object.read_more_ids) return []

            const readMoreBlocks = require('../assets/json/read-more_' + i18n.locale);

            return this.object.read_more_ids.map(({ id, type }) => {
                const block = readMoreBlocks.find(item => item.id === id)
                block.text = block.text.map(item => this.linksReadMore(item))
                return { type: type, ...block }
            })
        },
        isBack () {
            return this.$store.getters.startRoute && this.$store.getters.startRoute.name === 'home'
        },
        isInline () {
            return this.$route.query.inline === '1'
        },
        showGraph () {
            return !this.$route.query.include_charts || this.$route.query.include_charts === 'true'
        },
        measDate () {
            const measurement = this.$store.getters.measurements.find(({ type }) => type === this.categoryObj.measurement.key);
            return format(new Date(measurement.measureTime), 'MMM dd')
        },
        product () {
            const measurement = this.$store.getters.measurements.find(({ type }) => type === this.categoryObj.measurement.key)
            if (measurement == null) {
              return null;
            }
            const alerts = measurement.alerts;
            let product = undefined;
            if (alerts){
                alerts.forEach(alert => {
                    if (
                        alert.advice &&
                        alert.advice.action &&
                        alert.advice.action.addChemical &&
                        alert.advice.action.addChemical.shopProduct
                    ){
                        product = this.products.find(p => p.id === alert.advice.action.addChemical.shopProduct.id.toString());
                    }
                });
            }
            return product;
        },
        locale () {
            return i18n.locale;
        }
    },
    methods: {
        submitted ({ step, value }) {
            const actLambda = this.step.user_action_lambda
            if (actLambda) {
                lambda(actLambda.name, this.addValues(actLambda.payload, value))
                .then(data => this.$emit('next', { step, data }))
                .catch(() => this.$emit('next', { step }))
            } else {
                this.$emit('next', { step })
            }
        },
        init ({ name, payload }) {
            return lambda(name, this.addValues(payload))
            .then(data => {
                this.response = data
            })
        },
        addValues (payload, VALUE) {
            const VARS = {
                USER_ID: this.$route.query.user || this.$store.getters.userId,
                POOL_ID: this.$route.query.wb_id,
                VALUE: VALUE
            }
            return JSON.parse(Object.keys(VARS).reduce((str, key) => {
                return str.replace(`[${key}]`, VARS[key])
            }, JSON.stringify(payload)))
        },
        getData () {
            if (this.step.initial_lambdas) {
                Promise.all(this.step.initial_lambdas.map(item => this.init(item))).then(() => {

                })
            }
        },
        edit () {
            this.$store.dispatch('setBackRoute', this.$route)
        },
        back () {
            if (this.$store.getters.prevRoute.name === 'lab-results') {
                this.$router.push(this.$store.getters.prevRoute).catch(e => e)
            } else {
                this.$router.push({ name: 'home' }).catch(e => e)
            }
        },
        addAdvises () {
            this.object = JSON.parse(JSON.stringify(this.step.component.object))
            const category = this.$route.params.category ? this.$route.params.category.toUpperCase() : false
            const measurement = this.$store.getters.measurements.find(({ type }) => type === this.categoryObj.measurement.key)
            const alert = this.$store.getters.alerts.find(({ source, advice }) => source === category && !!advice)
            let advice = alert && alert.advice && alert.advice.action ? alert.advice.action.summary : false
            // if (advice && this.step.product && this.showProductPanel) {
            //     advice = advice + ` Purchase <a href="${DOMAIN}/checkout/?add-to-cart=${this.step.product.id}">${this.step.product.name}</a> in the WaterGuru Supplies store.`
            // }
            if (advice) {
                if (alert.advice.action.addChemical) {
                    this.object.advices[this.object.advices.indexOf('[SUMMARY]')] = advice
                } else {
                    this.object.advices = this.object.advices.reduce((acc, item) => {
                        if (item.includes('[STATIC]') || item === '[SUMMARY]') {
                            return [...acc, item.replace('[STATIC]', '')]
                        } else return acc
                    }, [])
                    this.object.advices[this.object.advices.indexOf('[SUMMARY]')] = advice
                    // this.object.advices = [advice]
                }
            } else if (!measurement) {
                this.object.advices = [this.categoryObj.measurement.not_measured_message]
            } else if (this.object.advices.some(item => item === '[SUMMARY]')) {
                // TODO: do not show even if no chemicals are recommended!
                this.object.advices = ['No action needed at this time']
            }

            if (advice && this.product && this.object.edit) {
                this.object.advices.splice(0, 1, {
                    text: this.object.advices[0],
                    button: {
                        label: this.object.edit.text,
                        onClick: () => this.$store.dispatch('setBackRoute', this.$route),
                        link: { name: 'wizard', query: { questions: this.object.edit.questions, title: this.categoryObj.edit_title } }
                    }
                })
            }
            this.object.advices = Object.keys(this.object.advices).reduce((acc, key) => {
                return [...acc, this.object.advices[key]];
            }, []);
        },

        getHistory (start, end) {
            const podId = this.$store.getters.pool.pods && this.$store.getters.pool.pods[0] ? this.$store.getters.pool.pods[0].podId : null
            if (podId && this.categoryObj) {
                return getHistory(podId, start.toISOString(), end.toISOString()).then(res => {
                    return res.filter(item => item[this.categoryObj.history_key] !== undefined).map(item => ({
                        time: item.time,
                        value: item[this.categoryObj.history_key] || 0
                    }))
                })
            }
        }
    },
    watch: {
        step: {
            deep: true,
            handler () {
                if (!this.step) return
                this.getData()
                this.addAdvises()
            }
        }
    },
    created () {
        if (!this.step) {
            return
        }
        this.getData()
        this.addAdvises()
    }
    ,
    beforeRouteEnter (to, from, next) {
        if (groupedSteps.every(({ category }) => category !== (to.params.category ? to.params.category.toUpperCase() : false))) {
            next({ name: 'home', query: to.query })
        } else {
            adviceGuard(to, from, next)
        }
    },
    beforeRouteUpdate (to, from, next) {
        if (groupedSteps.every(({ category }) => category !== (to.params.category ? to.params.category.toUpperCase() : false))) {
            next({ name: 'home', query: to.query })
        } else {
            adviceGuard(to, from, next)
        }
    }
}
</script>
